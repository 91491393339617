import { SiteFooterEntry } from "frontend/contentful/schema/universal";
import { SiteFooterProps } from "design-system/components/universal/site-footer/site-footer";
import { createSocialMediaLinksProps } from "frontend/contentful/components/universal/social-media-links.props";

import {
  renderBreakLineText,
  RenderContentfulRichText,
} from "../utils/render-contentful-rich-text";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { Link } from "design-system/components/primitives/link/link";
import { SocialMediaLinks } from "design-system/components/primitives/social-media-links/social-media-links";
import { useContentful } from "frontend/hooks/use-contentful";
import { createLinkProps } from "../primitives/link.props";

export const createSiteFooterProps = createContentfulProps<
  SiteFooterEntry,
  SiteFooterProps
>(({ entry, createEditAttributes }) => {
  const { getEntryUrl, getEntry, site } = useContentful();

  const siteType = site?.fields.domain === "my" ? "internal" : "external";

  const props: SiteFooterProps = {
    displayPrimaryCTA: false,
    footerBlocks: [],
    footerLegalItems: [],
  };

  const organization = getEntry(entry.fields.organization);

  if (organization) {
    const blockItems = [];
    // address goes first
    if (organization.fields?.address) {
      blockItems.push({
        children: (
          <p key={1}>{renderBreakLineText(organization.fields?.address)}</p>
        ),
        editAttributes: createEditAttributes({
          entry: organization,
          fieldId: "address",
        }),
      });
    }
    // email and phone
    if (organization.fields?.email || organization.fields?.phone) {
      blockItems.push({
        children: (
          <p>
            {organization.fields?.email && (
              <Link href={`mailto:${organization.fields?.email}`}>
                {organization.fields?.email}
              </Link>
            )}
            {organization.fields?.phone && (
              <>
                {organization.fields?.email && <br />}
                {organization.fields?.phone}
              </>
            )}
            {organization.fields?.fax && (
              <>
                {(organization.fields?.email || organization.fields?.phone) && (
                  <br />
                )}
                {`${organization.fields?.fax} (fax)`}
              </>
            )}
          </p>
        ),
        editAttributes: createEditAttributes({
          entry: organization,
          fieldId: "email",
        }),
      });
    }
    // Hours
    if (organization.fields?.hours) {
      blockItems.push({
        children: <p>{renderBreakLineText(organization.fields?.hours)}</p>,
        editAttributes: createEditAttributes({
          entry: organization,
          fieldId: "hours",
        }),
      });
    }
    props.footerBlocks?.push({
      title: organization.fields?.title,
      editAttributes: {
        title: createEditAttributes({ entry: organization, fieldId: "title" }),
      },
      items: blockItems,
    });
  }

  // links go second
  if (entry.fields.links) {
    props.footerBlocks?.push({
      items: entry.fields.links.map((link, i) => ({
        children: <Link key={i} {...createLinkProps(link)} />,
        editAttributes: createEditAttributes({ entry: link, fieldId: "title" }),
      })),
    });
  }

  // socials go last
  if (entry.fields.organization) {
    props.footerBlocks?.push({
      items: [
        {
          children: (
            <SocialMediaLinks
              {...createSocialMediaLinksProps(entry.fields.organization)}
            />
          ),
        },
      ],
    });
  }

  const cta = getEntry(entry.fields.cta);

  if (cta) {
    props.displayPrimaryCTA = true;
    props.footerPrimaryCta = {
      title: cta.fields.title,
      href: getEntryUrl(cta.fields.link),
      description: (
        <RenderContentfulRichText document={cta.fields.description} />
      ),
      editAttributes: {
        url: createEditAttributes({ entry: cta.fields.link, fieldId: "url" }),
        title: createEditAttributes({ entry: cta, fieldId: "title" }),
        description: createEditAttributes({
          entry: cta,
          fieldId: "description",
        }),
      },
    };
  }

  if (!props.footerLegalItems || props.footerLegalItems?.length === 0) {
    props.footerLegalItems = [
      {
        children: "Site Map",
        href: "https://www.hbs.edu/siteindex/",
      },
      {
        children: "Jobs",
        href: "https://www.hbs.edu/employment",
      },
      {
        children: "Harvard University",
        href: "https://www.harvard.edu",
      },
      {
        children: "Trademarks",
        href: "https://trademark.harvard.edu/pages/trademark-notice/",
      },
      {
        children: "Policies",
        href: "https://www.hbs.edu/about/campus-and-culture/policies",
      },
      {
        children: "Accessibility",
        href: "https://accessibility.harvard.edu/",
      },
      {
        children: "Digital Accessibility",
        href: "https://accessibility.huit.harvard.edu/digital-accessibility-policy",
      },
    ];
  }

  props.siteType = siteType;

  return props;
});
