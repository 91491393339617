import { PageProps, SearchResults } from "frontend/types";

export const hasSearchData = (data: SearchResults) =>
  typeof data === "object" && data?.hits;

export const setdropDownActive = (pageProps: PageProps) => {
  if (pageProps.request.domain === "library") return true;
  return false;
};

export const setdropDownUrl = (pageProps: PageProps) => {
  if (pageProps.request.domain === "library") return "/api/fetch/baker-search";
  return "";
};
